<template>
  <el-row :gutter="10">

    <el-col class="library-view-number-multiple-col" :span="8">
      <div><b>Min</b>: {{ scale.target_a ? (new Date(scale.target_a)).toLocaleDateString($companyConfiguration.helpers.getDateLocale()) : '' }}</div>
    </el-col>
    <el-col class="library-view-number-multiple-col" :span="8">
      <div><b>Цель</b>: {{ scale.target_b ? (new Date(scale.target_b)).toLocaleDateString($companyConfiguration.helpers.getDateLocale()) : '' }}</div>
    </el-col>
    <el-col class="library-view-number-multiple-col" :span="8">
      <div><b>Max</b>: {{ scale.target_c ? (new Date(scale.target_c)).toLocaleDateString($companyConfiguration.helpers.getDateLocale()) : '' }}</div>
    </el-col>

  </el-row>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";

export default {
  name: "library-view",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  data() {
    return {
      scale: {
        target_a: null,
        target_b: null,
        target_c: null,
        pay_a: null,
        pay_b: null,
        pay_c: null,
      },
    }
  },
  mounted() {

  },
  computed: {},
  methods: {}
}
</script>

<style>
.card-view-date {
  display: flex;
  justify-content: space-between;
}

.card-view-date div {
  white-space: nowrap;
}
</style>